@each $weight in (300, 500, 700) {
  @font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: $weight;
    src: url(https://fonts.northxsouth.co/museo-sans/#{$weight}.woff)
        format('woff'),
      url(https://fonts.northxsouth.co/museo-sans/#{$weight}.woff2)
        format('woff2');
  }

  @font-face {
    font-family: 'Museo Sans';
    font-style: italic;
    font-weight: $weight;
    src: url(https://fonts.northxsouth.co/museo-sans/#{$weight}i.woff)
        format('woff'),
      url(https://fonts.northxsouth.co/museo-sans/#{$weight}i.woff2)
        format('woff2');
  }
}

@each $weight in (500) {
  @font-face {
    // stylelint-disable-next-line
    font-family: 'Bakeshop';
    font-style: normal;
    font-weight: $weight;
    src: url('https://fonts.northxsouth.co/bakeshop/#{$weight}.woff')
        format('woff'),
      url('https://fonts.northxsouth.co/bakeshop/#{$weight}.woff2')
        format('woff2');
  }
}
