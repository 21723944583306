@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'leaflet/dist/leaflet.css';

@import './fonts';

html,
body,
#root,
#__next {
  margin: 0;
  height: 100%;
}

.skyline-footer {
  margin-bottom: -1rem;
  background: no-repeat url('/static/skyline-chicago.svg') center bottom / auto
      100%,
    repeat-x url('/static/skyline-repeat.svg') center bottom / auto 50%;
}

.map-bg {
  background-image: url('../images/Map Background@2x.png');
  background-size: cover;
  // background-color: #009344;
  // background-blend-mode: multiply;
}

.map {
  height: 100%;
}

.stamp-effect {
  -webkit-mask-image: url('/static/stamp-texture.png');
  -webkit-mask-size: 300px;
  mix-blend-mode: multiply;
}

.how-to-group {
  &:nth-child(3n + 2) {
    .stamp-effect {
      -webkit-mask-position-x: right;
    }
  }

  &:nth-child(3n + 3) {
    .stamp-effect {
      -webkit-mask-position-x: left;
      -webkit-mask-position-y: bottom;
    }
  }
}

.how-to-group,
.location-list-item {
  &:nth-child(4n + 2) {
    .stamp-effect {
      -webkit-mask-position-x: right;
    }
  }

  &:nth-child(4n + 3) {
    .stamp-effect {
      -webkit-mask-position-x: left;
      -webkit-mask-position-y: bottom;
    }
  }

  &:nth-child(4n + 4) {
    .stamp-effect {
      -webkit-mask-position-x: center;
      -webkit-mask-position-y: center;
    }
  }
}

.passport-stamp-item {
  .stamp-effect {
    transform: rotate(6deg);
  }

  &:nth-child(4n + 2) {
    .stamp-effect {
      -webkit-mask-position-x: right;
      transform: rotate(-12deg);
    }
  }

  &:nth-child(4n + 3) {
    .stamp-effect {
      -webkit-mask-position-x: left;
      -webkit-mask-position-y: bottom;
      transform: rotate(-18deg);
    }
  }

  &:nth-child(4n + 4) {
    .stamp-effect {
      -webkit-mask-position-x: center;
      -webkit-mask-position-y: center;
      transform: rotate(18deg);
    }
  }
}

.sponsor-row {
  img {
    width: auto;
    max-width: 120px;
    height: 50px;
    object-fit: contain;
    display: block;
    flex-shrink: 0;
  }

  &--large {
    img {
      max-width: 150px;
      height: 90px;
    }
  }
}

.ReactQueryDevtools {
  position: absolute;
}

#__next {
  @apply flex min-h-screen flex-col justify-between;
}
